import { Injectable, computed, signal } from '@angular/core';
import { Maybe } from '@designage/gql';
import { DateTimeFormat, dateFormats, timeFormats } from '@desquare/models';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { DateTime } from 'ts-luxon';
import { getTimeZones, rawTimeZones, timeZonesNames } from '@vvo/tzdb';

interface ITimezoneObject {
  timezone: string;
  offset: string;
}
@Injectable({
  providedIn: 'root',
})
export class ProfileSettingsService {
  public profileTimezone = signal<string | null>(DateTime.local().zoneName);
  public profileDateFormat = signal<DateTimeFormat>(dateFormats[0]);
  public profileTimeFormat = signal<DateTimeFormat>(timeFormats[0]);

  timezones = getTimeZones();
  timezoneObject = computed(() => {
    const ret: ITimezoneObject = {
      timezone: this.profileTimezone() || 'Europe/Stockholm',
      offset:
        this.timezones
          .find((x) => x.name === this.profileTimezone())
          ?.currentTimeOffsetInMinutes.toString() ||
        Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    return ret;
  });
  // Timezone
  getProfileTimezoneValue() {
    return this.timezoneObject();
  }
  setProfileTimezone(timezone: string = 'Europe/Stockholm') {
    this.profileTimezone.set(timezone || '');
  }

  // DateFormats
  setProfileDateFormatByKey(name: Maybe<string> = 'Day Month Year') {
    const newFormat = dateFormats.find((x) => x.name === name);
    if (newFormat) {
      this.setProfileDateFormat(newFormat);
    }
  }
  setProfileDateFormat(format: DateTimeFormat) {
    this.profileDateFormat.set(format);
  }

  // TimeFormats
  setProfileTimeFormatByKey(name: Maybe<string> = '24 hour') {
    const newFormat = timeFormats.find((x) => x.name === name);
    if (newFormat) {
      this.setProfileTimeFormat(newFormat);
    }
  }
  private setProfileTimeFormat(format: DateTimeFormat) {
    this.profileTimeFormat.set(format);
  }
}
